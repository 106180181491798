import { ChangeDetectorRef, Directive, HostBinding, Input, OnDestroy, OnInit, inject } from '@angular/core';

/**
 * Directive that tracks the focus state of an input element and applies a CSS class when focused.
 */
@Directive({
  selector: '[trackInputFocus]',
  standalone: true,
})
export class TrackInputFocusDirective implements OnInit, OnDestroy {
  /**
   * Indicates whether the input element is currently focused.
   */
  @HostBinding('class.is-focused') isFocused: boolean = false;

  /**
   * The input element to track focus on.
   */
  @Input('trackInputFocus') input!: HTMLInputElement;

  /**
   * Reference to the ChangeDetectorRef instance.
   */
  private cd = inject(ChangeDetectorRef);

  ngOnInit(): void {
    this.input.addEventListener('focus', this.onFocus);
    this.input.addEventListener('blur', this.onBlur);
  }

  ngOnDestroy(): void {
    this.input.removeEventListener('focus', this.onFocus);
    this.input.removeEventListener('blur', this.onBlur);
  }

  /**
   * Event handler for the focus event.
   * Sets the `isFocused` flag to true and triggers change detection.
   *
   * @param e - The focus event object.
   */
  private onFocus = (e: Event) => {
    this.isFocused = true;
    this.cd.markForCheck();
  };

  /**
   * Event handler for the blur event.
   * Sets the `isFocused` property to `false` and triggers change detection.
   * @param e - The blur event object.
   */
  private onBlur = (e: Event) => {
    this.isFocused = false;
    this.cd.markForCheck();
  };
}
